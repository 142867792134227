import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"

import Header from "../components/Home/Header"
import Footer from "../components/Footer"
import "./layout.css"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#333333",
  },
  main: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    backgroundColor: "#333333",
  },
}))

const HomeLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <Header />
        <div className={classes.content}>{children}</div>
        <Footer />
      </main>
    </div>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeLayout
