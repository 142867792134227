import React from "react"
import { makeStyles, AppBar, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    backgroundColor: "#333333",
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <AppBar className={classes.footer} position="relative">
      <Typography variant="body1" noWrap>
        © {new Date().getFullYear()} WOOFCODE
      </Typography>
    </AppBar>
  )
}

export default Footer
