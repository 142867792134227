import React, { useState } from "react"
import {
  Container,
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  Button,
} from "@material-ui/core"
import { Menu, Close } from "@material-ui/icons"
import { Link } from "gatsby"

import logo from "../../images/logo.png"
import useIsMobile from "../../hooks/useIsMobile"

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: "#ffffff",
    color: "#2d271e",
    paddingTop: "40px",
  },
  spreader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "2.5rem",
    marginBottom: 0,
  },
  links: {
    display: "flex",
  },
  link: {
    textDecoration: `none`,
    color: "#8f8f8f",
    paddingLeft: "40px",
  },
  mobileDialog: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  closeIcon: {
    width: "32px",
    height: "32px",
  },
  button: {
    fontSize: "1.5rem",
    letterSpacing: "0.06rem",
  },
}))

const Header = () => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)

  return (
    <header className={classes.header}>
      <Container maxWidth="lg">
        <div className={classes.spreader}>
          <a href="/" className={classes.logoWrapper}>
            <img src={logo} className={classes.logo} alt="logo" />
          </a>
          {isMobile ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(true)}
            >
              <Menu />
            </IconButton>
          ) : (
            <div className={classes.links}>
              <Link to="/learn" className={classes.link}>
                <Typography variant="h6">Curriculum</Typography>
              </Link>
              <Link to="/syllabus" className={classes.link}>
                <Typography variant="h6">Syllabus</Typography>
              </Link>
            </div>
          )}
        </div>
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <Container className={classes.mobileDialog}>
          <IconButton
            edge="end"
            className={classes.closeButton}
            color="inherit"
            size="medium"
            onClick={() => setOpen(false)}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <Link to="/learn" className={classes.link}>
            <Button
              className={classes.button}
              size="large"
              color="primary"
              fullWidth
            >
              Curriculum
            </Button>
          </Link>
          <Link to="/syllabus" className={classes.link}>
            <Button
              className={classes.button}
              size="large"
              color="primary"
              fullWidth
            >
              Syllabus
            </Button>
          </Link>
        </Container>
      </Dialog>
    </header>
  )
}

export default Header
